<template>
  <!--begin::Basic info-->
  <div class="card mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <h3 class="fw-bolder m-0 text-white">Cari Wilayah</h3>
        <div
          class="
            float-end
            d-flex
            align-items-right
            justify-content-between
            w-50
          "
        >
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKecamatan"
            v-model="form.kecamatan"
          >
            <option value="" selected>Kecamatan</option>
            <option
              v-for="(item, i) in kecamatan"
              :key="i"
              :value="item.territory.id"
            >
              {{ item.territory.name }}
            </option>
          </select>
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKelurahan"
            v-model="form.kelurahan"
          >
            <option value="" selected>Kelurahan</option>
            <template v-if="kelurahan">
              <option
                v-for="(item, i) in kelurahan"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select>
          <!-- <select
            name=""
            id=""
            class="form-select me-2 w-50"
            @input="getRw"
            v-model="form.rw"
          >
            <option value="" selected>RW</option>
            <template v-if="rw">
              <option
                v-for="(item, i) in rw"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select>
          <select
            name=""
            id=""
            class="form-select me-2 w-50"
            @input="getTps"
            v-model="form.tps"
          >
            <option value="" selected>TPS</option>
            <template v-if="tps">
              <option
                v-for="(item, i) in tps"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select> -->
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-0">
        <!--begin::Input group-->
        <!-- <div class="row mb-6"> -->
        <div style="height: 64vh; width: 100%">
          <!-- <GMapMap
            :center="center"
            :zoom="7"
            map-type-id="roadmap"
            style="width: 100%; height: 64vh"
          >
            <GMapCluster>
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.location"
                :clickable="true"
                :draggable="false"
                @click="center = m.location"
              />
            </GMapCluster>
            <GMapHeatmap :data="heatdata"></GMapHeatmap>
          </GMapMap> -->
          <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :zoomAnimation="true"
            :options="{ zoomControl: false }"
            :center="[-6.304159, 106.724997]"
          >
            <div
              class="card bg-white rounded position-absolute ms-5 mt-10"
              style="z-index: 2"
            >
              <div class="card-body border-top p-5">
                <div class="fs-5 fw-bolder">Keterangan :</div>
                <ul class="list-unstyled mb-0">
                  <li v-for="(item, i) in info" :key="i" class="m-0 p-0">
                    <img class="me-1" :src="item.img" width="40" />
                    <span class="fs-8"> {{ item.text }} </span>
                  </li>
                </ul>
              </div>
            </div>

            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            />
            <l-control-layers />
            <l-control-zoom position="bottomright"></l-control-zoom>

            <template v-for="item in markers" :key="item.territory.id">
              <l-marker
                :lat-lng="[item.territory.latitude, item.territory.longitude]"
                @click="
                  getDetail(
                    $CryptoJS.AES.encrypt(
                      item.territory.id.toString(),
                      'PKS.id'
                    ).toString()
                  )
                "
              >
                <l-icon
                  :icon-url="
                    item.zona_pks == 1
                      ? info[4].img
                      : item.zona_pks == 2
                      ? info[3].img
                      : item.zona_pks == 3
                      ? info[2].img
                      : item.zona_pks == 4
                      ? info[1].img
                      : info[0].img
                  "
                  :icon-size="iconSize"
                />
              </l-marker>
              <l-polygon
                :lat-lngs="JSON.parse(item.territory.border)"
                :color="
                  item.zona_pks == 1
                    ? info[4].color
                    : item.zona_pks == 2
                    ? info[3].color
                    : item.zona_pks == 3
                    ? info[2].color
                    : item.zona_pks == 4
                    ? info[1].color
                    : info[0].color
                "
              />
            </template>
          </l-map>
        </div>
        <!-- </div> -->
        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LPolygon,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LPolygon,
  },
  setup() {
    setCurrentPageTitle("Heatmaps");

    const store = useStore();
    const router = useRouter();

    store.commit("SET_ACTIVE_MENU", "heatmaps");
    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("AllHeatmap", {  });

    const center = ref([-6.304159, 106.724997]);
    const path = window.location.origin + "/";

    const heatdata = ref();

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const markers = computed(() => store.state.HeatmapModule.all);
    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);
    const rw = computed(() => store.state.TerritoryModule.rw);
    const tps = computed(() => store.state.TerritoryModule.tps);

    const form = ref({
      kecamatan: "",
      kelurahan: "",
      rw: "",
      tps: "",
    });

    const info = ref([
      {
        img: path + "other/png/vuesax-bold-location-3.png",
        text: "Zona Sangat Unggul",
        color: "blue",
      },
      {
        img: path + "other/png/vuesax-bold-location-1.png",
        text: "Zona Unggul",
        color: "green",
      },
      {
        img: path + "other/png/vuesax-bold-location.png",
        text: "Zona Menengah",
        color: "yellow",
      },
      {
        img: path + "other/png/vuesax-bold-location-4.png",
        text: "Zona Minim",
        color: "orange",
      },
      {
        img: path + "other/png/vuesax-bold-location-2.png",
        text: "Zona Sangat Minim",
        color: "red",
      },
    ]);

    const getDetail = (id) => {
      router.push({ name: "heat-maps.chart", params: { id: id } });
    };

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        // store.dispatch("AllTerritory", { type: 6, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kecamatan);
        // store.commit("SET_ALL_TERRITORY", { type: 6, territories: [] });
      }
    };

    const getRw = (el) => {
      form.value.rw = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 7, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kelurahan);
        store.commit("SET_ALL_TERRITORY", { type: 7, territories: [] });
      }
    };

    const getTps = (el) => {
      if (el.target.value) {
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.rw);
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllHeatmap", {
        territory: wilayah,
      });
    };

    const updateTanpaWilayah = () => {
      store.dispatch("AllHeatmap");
    };

    return {
      center,
      markers,
      info,
      zoom,
      iconSize,
      heatdata,
      form,
      kecamatan,
      kelurahan,
      rw,
      tps,
      getDetail,
      getKecamatan,
      getKelurahan,
      getRw,
      getTps,
    };
  },
});
</script>
